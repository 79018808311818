.with-bg{
    /* --background: rgb(2,175,160); */
    /* --background: linear-gradient(315deg, rgba(2,175,160,1) 0%, rgba(13,235,216,1) 100%); */
    --background: radial-gradient( circle 1224px at 10.6% 8.8%,  rgb(219, 251, 253) 0%, rgba(2,175,160,1) 100.2% );
    /* --background: var(--ion-color-primary);  */
    /* --background: -webkit-linear-gradient(to right, var(--ion-color-primary), var(--ion-color-primary-shade));   */
    /* --background: linear-gradient(to right, var(--ion-color-primary), var(--ion-color-primary-shade));  */
}


.my-title{
    text-align: center;
    padding-bottom: 15px;
}
.side-box{
    width: 100%;
    padding: 20px 15px;
    text-align: center;
}
.side-box-logo{
    width: 100%;
    height: 100px;
}
.modal-step2{
    --height: auto;
}
.modal-step2 ion-backdrop{
    --backdrop-opacity: 0.5;
}
.modal-step2 .modal-wrapper{
    width: 400px;
    max-width: 80%;
}
.modal-step2 .modal-wrapper .ion-page {
    position: relative;
    contain: content;
    max-height: 90vh;
}
.modal-step2 .modal-wrapper .ion-page .modal-content {
    overflow: auto;
}

.verify-by{
    margin-top: 25px;
    margin-left: 25px;
    text-align: left;
}
.verify-by img{
    height: 32px;
    margin-right: 15px;
}
.side-box-footer{
    position: absolute;
    bottom: 0;
    left:0;
    color: var(--ion-color-light);
    display: none;
}

.err-code{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 48pt;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.err-message{
    text-align: center;
    margin-bottom: 100px;
}
.text-paid{
    color: #2dd36f;
}

.modal-alert{
    --height: auto;
}
.modal-alert ion-backdrop{
    --backdrop-opacity: 0.5;
}
.modal-alert .modal-wrapper{
    width: 400px;
    max-width: 80%;
}
.modal-alert .modal-wrapper .ion-page {
    position: relative;
    contain: content;
    max-height: 90vh;
}
.modal-alert .modal-wrapper .ion-page .modal-content {
    overflow: auto;
}

@media (min-width: 768px){
    ion-card{
        width: 580px;
        /* margin: 25px auto; */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .side-box{
        width: 200px;
    }
    .side-box-footer{
        display: block;
    }
}

@media (max-width: 425px){
    .side-col{
        width: 100% !important;
    }
}